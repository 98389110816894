body {
  font-size: 17px !important;
  font-family: 'Poppins', sans-serif !important;
  font-weight: 600 !important;
  overflow: hidden;
}

.items-list-container {
  overflow-y: scroll;
  max-height: 550px;
  width: 100%;
}

.items-list-container::-webkit-scrollbar,
.scroll-container-home::-webkit-scrollbar {
  width: 6px;
}

.items-list-container::-webkit-scrollbar-track,
.scroll-container-home::-webkit-scrollbar-track {
  background: transparent;
}

.items-list-container::-webkit-scrollbar-thumb,
.scroll-container-home::-webkit-scrollbar-thumb {
  background-color: #616161;
  border-radius: 10px;
}

.list-item-hover {
  cursor: pointer;
}

.list-item-hover:hover {
  background: rgba(68, 68, 68, 0.103);
}

.card-home {
  transition-duration: 0.2s;
}

.card-home:hover {
  cursor: pointer;
  transform: scale(1.03);
}

.scroll-container-home {
  height: calc(100vh - 300px);
  overflow-y: scroll;
  overflow-x: hidden;
}

/* * {
  z-index: 100;
  overflow: hidden;
} */

/* @media only screen and (min-width: 1024px) {
  .items-list-container {
    max-height: 550px;
  }
}

@media only screen and (min-width: 1366px) {
  .items-list-container {
    max-height: 934px;
  }
} */

.tipotitulo {
  text-transform: capitalize;
}

.shadow-bg {
  height: 100%;
  display: block;
  background: rgba(0, 0, 0, 0.024);
  position: absolute;
  z-index: 1 !important;
  transform: skewX(-15deg);
}

.shadow-bg-1 {
  width: 500px;
  left: -100px;
}

.shadow-bg-2 {
  width: 200px;
  left: 30px;
}

.shadow-bg-3 {
  width: 200px;
  left: 150px;
}

.shadow-bg-4 {
  width: 500px;
  right: -100px;
}

.shadow-bg-5 {
  width: 200px;
  right: 60px;
}

.shadow-bg-6 {
  width: 200px;
  right: -100px;
}
